// import React from 'react'
// import { Detector } from "react-detect-offline";

// const NetworkLost = ({ children }) => {
//   return (
//     <div>
//       <Detector
//         render={({ online }) =>
//           online ? (
//             children
//           ) : (
//             <div style={{ paddingTop: "10px", textAlign: "center" }}>
//               {/* <PiWifiX size={100} /> */}
//               <h1 style={{ marginBottom: "5px" }}>No Connection</h1>
//               <h4 style={{ marginBottom: "5px" }}>
//                 Please check your internet connection
//               </h4>
//             </div>
//           )
//         }
//       />
//     </div>
//   );
// };

// export default NetworkLost


