import React, { useEffect, useState } from "react";
import IMG from "../../assets/images/Background.png";
import IMG1 from "../../assets/images/Vector123.png";
import IMG2 from "../../assets/images/image 327 (1).png";
import IMG3 from "../../assets/images/India.png";
import "../GiveAwayWinner/GiveAwayWiner.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalIsOpen,
  setShowPopup,
  setwinnerProfile,
} from "../Slice/UserSlice";
import GiveAwayPopup from "./GiveAwayPopup";

const getAvatarFromUserName = (userName) => {
  if (!userName) return "";
  const firstChar = userName.charAt(0).toUpperCase();
  const colorMapping = {
    A: "#FF5733",
    B: "#33FF57",
    C: "#3357FF",
    D: "#F1C40F",
    E: "#E67E22",
    F: "#2ECC71",
    G: "#3498DB",
    H: "#9B59B6",
    I: "#1ABC9C",
    J: "#34495E",
    K: "#E74C3C",
    L: "#8E44AD",
    M: "#2C3E50",
    N: "#16A085",
    O: "#27AE60",
    P: "#2980B9",
    Q: "#8E44AD",
    R: "#D35400",
    S: "#C0392B",
    T: "#F39C12",
    U: "#C0C0C0",
    V: "#7D3C98",
    W: "#F7DC6F",
    X: "#D5DBDB",
    Y: "#F5B041",
    Z: "#AAB7B8",
  };

  const backgroundColor = colorMapping[firstChar] || "#BDC3C7";

  return {
    backgroundColor,
    text: firstChar,
  };
};

const GiveAwayWinner = () => {
  const { WebCampaignDetails, showPopup, modalIsOpen } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const URL = process.env.REACT_APP_BASE_API_URL;

  const [winnerListData, setwinnerListData] = useState([]);

  const fetchWinnerList = async () => {
    const response = await axios.post(`${URL}campaign/getWinners`, {
      campaignId: WebCampaignDetails?.campaignData?._id,
      userId: WebCampaignDetails?.userData?.userId,
    });
    setwinnerListData(response?.data?.data || []);
    console.log("response", response?.data?.data);

    const winnerListData = response?.data?.data;
    setwinnerListData(winnerListData);

    const userIdToCompare = parseInt(WebCampaignDetails?.userData?.userId);
    const winnerData = winnerListData.find(
      (winner) => userIdToCompare === parseInt(winner?.userId)
    );

    if (winnerData) {
      dispatch(setwinnerProfile(winnerData));
    }
  };

  useEffect(() => {
    fetchWinnerList();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setShowPopup(true));
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const GiveAwayBg = {
    backgroundImage: `url(${IMG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingTop: "85px",
    height: "100vh",
    overflow: "auto",
  };
  const GiveAwayBg2 = {
    backgroundImage: `url(${IMG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    overflow: "auto",
  };

  const GiveAwayWinnerStyle = {
    backgroundImage: `url(${IMG1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
  };

  // const userIdToCompare = parseInt(WebCampaignDetails?.userData?.userId);
  // const winnerData2 = winnerListData.find(
  //   (winner) => userIdToCompare === parseInt(winner?.userId)
  // );

  // const userIdToCompare = Number(WebCampaignDetails?.userData?.userId);
  // console.log("userIdToCompare", userIdToCompare);
  // const winnerData2 = Array.isArray(winnerListData)
  //   ? winnerListData.find(
  //       (winner) => Number(winner?.userId) === userIdToCompare
  //     )
  //   : null;
  const userIdToCompare = WebCampaignDetails?.userData?.userId;
  console.log("userIdToCompare", userIdToCompare);
  const winnerData2 = Array.isArray(winnerListData)
    ? winnerListData.find((winner) => winner?.userId === userIdToCompare)
    : null;

  // console.log("winnerData2", winnerData2);

  // console.log(
  //   "WebCampaignDetails?.userData?.userId",
  //   WebCampaignDetails?.userData?.userId
  // );
  // console.log("winnerData2>>>>>>>>>>", winnerData2.userId);

  // console.log(
  //   "userIdToCompare1>>>>>>>>>>>>",
  //   parseInt(winnerData2.userId) === parseInt(WebCampaignDetails?.userData?.userId)
  // );

  return (
    <div>
      <div>
        {showPopup ? (
          <>
            <div className="giveaway-popup">
              {/* {winnerData2 && (
                <GiveAwayPopup
                  isOpen={modalIsOpen}
                  onRequestClose={() => dispatch(setModalIsOpen(false))}
                />
              )} */}
              {winnerData2 ? (
                <GiveAwayPopup
                  isOpen={modalIsOpen}
                  onRequestClose={() => dispatch(setModalIsOpen(false))}
                />
              ) : (
                <>
                  <div style={GiveAwayBg2} className="">
                    <div
                      className="p-[20px] h-[100vh] overflow-y-auto"
                      style={GiveAwayWinnerStyle}
                    >
                      <div className="flex items-center px-[47px] py-[10px] border-[3px] border-[#DFBC5F] rounded-[24px] justify-center mt-[15px]">
                        <img
                          src={IMG2}
                          alt="img-winner"
                          className="w-[68px] h-[68px] winner-list-trophy"
                        />
                        <p className="font-bold leading-[22.68px] text-[18px] text-[#FFFFFF] ml-[10px] giveaway-winner-all">
                          Giveaway Winners
                        </p>
                      </div>

                      <div className=" flex pt-[30px] w-[100%]">
                        <div className="bg-[#333333] p-[10px] rounded-[20px] w-[100%] giveaway-winnners-contest">
                          {winnerListData.map((detail, index) => {
                            const avatarData = getAvatarFromUserName(
                              detail.userName
                            );
                            return (
                              <div key={index}>
                                <div className="bg-[#1C1C1C] rounded-[20px] py-[10px] px-[16px] mb-[16px] giveaway-winner-details">
                                  <div className="flex items-center">
                                    <div className="w-[24px] h-[24px] rounded-[12px] border-[1.5px] border-[#656565] flex justify-center items-center">
                                      <p className="text-[#FEFFFA] text-[12px] font-semibold leading-[15.12px]">
                                        {detail.winnerRank}
                                      </p>
                                    </div>
                                    <div
                                      className="giveaway-winner-avtar flex justify-center items-center winner-profile"
                                      style={{
                                        backgroundColor:
                                          avatarData.backgroundColor,
                                        width: "49px",
                                        height: "49px",
                                        borderRadius: "50%",
                                      }}
                                    >
                                      <p className="text-[#FEFFFA] text-[20px] font-semibold">
                                        {avatarData.text}
                                      </p>
                                    </div>

                                    <div className="relative top-[15px] left-[-10px]">
                                      <img src={IMG3} alt="indian-flag" />
                                    </div>
                                  </div>

                                  <div className="giveaway-winner-name">
                                    <h1 className="text-[16px] font-bold leading-[20.16px] text-[#FEFFFA] giveaway-name-win">
                                      {detail.userName}
                                    </h1>
                                    <p className="font-normal text-[14px] text-[#656565] leading-[19.6px] pt-[8px] giveaway-prize-name">
                                      {detail.productDetails.productName}
                                    </p>
                                  </div>
                                  <div className=" giveaway-pize-img">
                                    <img
                                      src={
                                        detail.productDetails.productImages[0]
                                      }
                                      alt="winner-prize"
                                      className="w-[60px] h-[47.04px] winner-profile-image"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div style={GiveAwayBg} className="giveaway-all-winmer-list">
              <div
                className="p-[20px] h-[100vh] overflow-y-auto"
                style={GiveAwayWinnerStyle}
              >
                <div className="flex items-center px-[47px] py-[10px] border-[3px] border-[#DFBC5F] rounded-[24px] justify-center mt-[15px]">
                  <img
                    src={IMG2}
                    alt="img-winner"
                    className="w-[68px] h-[68px] winner-list-trophy"
                  />
                  <p className="font-bold leading-[22.68px] text-[18px] text-[#FFFFFF] ml-[10px] giveaway-winner-all">
                    Giveaway Winners
                  </p>
                </div>

                <div className=" flex pt-[30px] w-[100%]">
                  <div className="bg-[#333333] p-[10px] rounded-[20px] w-[100%] giveaway-winnners-contest">
                    {winnerListData.map((detail, index) => {
                      const avatarData = getAvatarFromUserName(detail.userName);
                      return (
                        <div key={index}>
                          <div className="bg-[#1C1C1C] rounded-[20px] py-[10px] px-[16px] mb-[16px] giveaway-winner-details">
                            <div className="flex items-center">
                              <div className="w-[24px] h-[24px] rounded-[12px] border-[1.5px] border-[#656565] flex justify-center items-center">
                                <p className="text-[#FEFFFA] text-[12px] font-semibold leading-[15.12px]">
                                  {detail.winnerRank}
                                </p>
                              </div>
                              <div
                                className="giveaway-winner-avtar flex justify-center items-center winner-profile"
                                style={{
                                  backgroundColor: avatarData.backgroundColor,
                                  width: "49px",
                                  height: "49px",
                                  borderRadius: "50%",
                                }}
                              >
                                <p className="text-[#FEFFFA] text-[20px] font-semibold">
                                  {avatarData.text}
                                </p>
                              </div>

                              <div className="relative top-[15px] left-[-10px]">
                                <img src={IMG3} alt="indian-flag" />
                              </div>
                            </div>

                            <div className="giveaway-winner-name">
                              <h1 className="text-[16px] font-bold leading-[20.16px] text-[#FEFFFA] giveaway-name-win">
                                {detail.userName}
                              </h1>
                              <p className="font-normal text-[14px] text-[#656565] leading-[19.6px] pt-[8px] giveaway-prize-name">
                                {detail.productDetails.productName}
                              </p>
                            </div>
                            <div className=" giveaway-pize-img">
                              <img
                                src={detail.productDetails.productImages[0]}
                                alt="winner-prize"
                                className="w-[60px] h-[47.04px] winner-profile-image"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GiveAwayWinner;
