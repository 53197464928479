import React, { useEffect } from "react";
import img1 from "../../assets/images/Icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const PhonepePopup = () => {
  //  const { pathGetUrl } = useSelector((state) => state.user);
  //  console.log("pathGetUrl}}}}}}}}}}}}}}}}}}}}}}", pathGetUrl);

  // const navigate = useNavigate();

  // const handleDoneClick = () => {
  //   navigate(-6)
  // }

  const navigate = useNavigate();
  const { campaignName, id } = useParams();

  const routes = {
    success: "/success-payment",
    failed: "/failed-payment",
    campaign: `/${campaignName}/${id}`,
  };

  const handleDoneClick = () => {
    navigate(routes.campaign);
  };

  const URL = process.env.REACT_APP_BASE_API_URL;
  const fetchgetWebCampaignDetails = async (campaignName, campaignId) => {
    try {
      const response = await axios.get(
        `${URL}campaign/getWebCampaignDetails/${campaignName}/${campaignId}`
      );
    } catch (error) {
      console.error("Error fetching campaign details:", error);
    }
  };

  useEffect(() => {
    if (campaignName && id) {
      fetchgetWebCampaignDetails(campaignName, id);
    } else {
      console.error("Missing campaignName or id:", campaignName, id);
    }
  }, [campaignName, id]);

  return (
    <div className="flex h-[100vh] justify-center items-center bg-[#1C1C1C]">
      <div className="px-[28px] border-[1px] border-[#62CD5D] rounded-[20px]">
        <div className="pt-[70px] flex justify-center items-center">
          <img src={img1} alt="popup-img" className="w-[172px] h-[163px]" />
        </div>

        <h2 className="flex justify-center items-center font-bold text-[25px] leading-[31.5px] text-[#62CD5D] pt-[10px] Payment-successfully">
          Payment Successfully
        </h2>
        <div className="flex justify-center items-center">
          <p className="text-center pt-[10px] text-[#656565] text-[12px] leading-[15px] font-semibold w-[240px] ">
            Your payment of ₹99 was Successfully completed.
          </p>
        </div>
        {/* <p className="text-center pt-[10px] text-[#656565] text-[12px] leading-[15px] font-semibold">
          Payment ID : 123456, 13 March 2024-12.12 PM
        </p> */}
        <div className="pt-[90px] ">
          <p className="text-[#FFFFFF] text-[12px] font-semibold leading-[15px] flex justify-center">
            Thanks for being there with us.
          </p>
          <button
            onClick={handleDoneClick}
            className="py-[25px] bg-[#62CD5D] text-[#1C1C1C] text-[16px] font-bold rounded-[20px] mt-[18px] mb-[30px] w-full"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhonepePopup;
